body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: var(--background-dim, #f3f3f3);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.sd-root-modern{
  height: calc( 100vh - 100px);
  font-family: 'PT Sans', 'Helvetica Neue', sans-serif ; 

}
.sd-body__navigation{
  display: flex;
  justify-content: flex-end;
}

@media (max-width:600px) {
  .sd-body.sd-body--responsive .sd-body__navigation{
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    width: 100%;
    padding: 0 !important;
    flex-direction: column-reverse;
  }
  .sv-action{
    margin-bottom: 20px;
  }
  #sv-nav-next, #sv-nav-complete {
    margin-left: 0;
  }
      
}

input.sd-btn.sd-navigation__prev-btn {
  background: none;
  box-shadow: none;
  color: #999;
}

.surveyElement.completed{
  height:auto;
}

.loader{
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
