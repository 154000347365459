body .surveyElement {
    --primary: #47a404;
    --primary-light: rgba(25, 179, 148, 0.1);
    --primary-foreground: #fff;
    --secondary: #47a404;
    --secondary-light: rgba(255, 152, 20, 0.1);
    --secondary-foreground: #fff;
    --background: #f8f8f8;
    --background-dim: #f3f3f3;
    --background-for-editors: #f9f9f9;
    --foreground: #4a4a4a;
    --foreground-light: #909090;
    --foreground-disabled: rgba(#161616, 0.16);
    --border: #d6d6d6;
    --border-inside: rgba(0, 0, 0, 0.16);
    --red: #e60a3e;
    --red-light: rgba(230, 10, 62, 0.1);
    --green: #47a404;
    --green-light: rgba(25, 179, 148, 0.1);
    height:auto;
    /* text-rendering: optimizeLegibility !important; -webkit-font-smoothing: antialiased !important;
 */
  }

    body * {
        font-family: 'PT Sans', 'Helvetica Neue', sans-serif !important;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }

.sd-rating__item{
    border-radius: 6px;
}

.sd-question__erbox--above-question{
    margin-top:0;
}

.sd-page .br-theme-css-stars .br-widget a:after{
    max-width: 40px;
    max-height: 40px;
}

.sd-page .br-theme-css-stars .br-widget a{
    height: auto;
}

.sd-page .br-theme-css-stars .br-widget a[data-rating-value="1"]:after{
    content: url('images/1.svg');
}
.sd-page .br-theme-css-stars .br-widget a.br-current[data-rating-value="1"]:after, .sd-page .br-theme-css-stars .br-widget a.br-active[data-rating-value="1"]:after{
    content: url('images/1-selected.svg');
}


.sd-page .br-theme-css-stars .br-widget a[data-rating-value="2"]:after{
    content: url('images/2.svg');
}
.sd-page .br-theme-css-stars .br-widget a.br-current[data-rating-value="2"]:after{ /*, .sd-page .br-theme-css-stars .br-widget a.br-active[data-rating-value="2"]:after{*/
    content: url('images/2-selected.svg');
}




.sd-page .br-theme-css-stars .br-widget a[data-rating-value="3"]:after{
    content: url('images/3.svg');
}
.sd-page .br-theme-css-stars .br-widget a.br-current[data-rating-value="3"]:after, .sd-page .br-theme-css-stars .br-widget a.br-active[data-rating-value="3"]:after{
    content: url('images/3-selected.svg');
}


.sd-page .br-theme-css-stars .br-widget a[data-rating-value="4"]:after{
    content: url('images/4.svg');
}
.sd-page .br-theme-css-stars .br-widget a.br-current[data-rating-value="4"]:after, .sd-page .br-theme-css-stars .br-widget a.br-active[data-rating-value="4"]:after{
    content: url('images/4-selected.svg');
}


.sd-page .br-theme-css-stars .br-widget a[data-rating-value="5"]:after{
    content: url('images/5.svg');
}
.sd-page .br-theme-css-stars .br-widget a.br-current[data-rating-value="5"]:after, .sd-page .br-theme-css-stars .br-widget a.br-active[data-rating-value="5"]:after{
    content: url('images/5-selected.svg');
}


.sd-page .br-theme-css-stars .br-widget a[data-rating-value="6"]:after{
    content: url('images/6.svg');
}
.sd-page .br-theme-css-stars .br-widget a.br-current[data-rating-value="6"]:after, .sd-page .br-theme-css-stars .br-widget a.br-active[data-rating-value="6"]:after{
    content: url('images/6-selected.svg');
}

/*
.sd-page .br-theme-css-stars .br-widget a.br-current[data-rating-value="6"]:after + .sd-page .br-theme-css-stars .br-widget a.br-selected::after{
    content: url('images/6-selected.svg');
}*/

.sd-page .br-theme-css-stars .br-widget a[data-rating-value="none"]:after{
    content: url('images/none_sv.svg');
}
.sd-page .br-theme-css-stars .br-widget a.br-current[data-rating-value="none"]:after, .sd-page .br-theme-css-stars .br-widget a.br-active[data-rating-value="none"]:after{
    content: url('images/none-selected_sv.svg');
}

/* Finnish 'None label' */ 
.lang-fi .sd-page .br-theme-css-stars .br-widget a[data-rating-value="none"]:after{
    content: url('images/none_fi.svg');
}
.lang-fi .sd-page .br-theme-css-stars .br-widget a.br-current[data-rating-value="none"]:after, .sd-page .br-theme-css-stars .br-widget a.br-active[data-rating-value="none"]:after{
    content: url('images/none-selected_fi.svg');
}

/* Norwegian 'None label' */ 
.lang-no .sd-page .br-theme-css-stars .br-widget a[data-rating-value="none"]:after{
    content: url('images/none_no.svg');
}
.lang-no .sd-page .br-theme-css-stars .br-widget a.br-current[data-rating-value="none"]:after, .sd-page .br-theme-css-stars .br-widget a.br-active[data-rating-value="none"]:after{
    content: url('images/none-selected_no.svg');
}


.sd-page .br-theme-css-stars .br-widget a{
    font-size: 2em;
    width: auto;
}

.br-widget:has(a.br-active[data-rating-value="2"]) .br-active:after, .br-widget:has(a.br-selected[data-rating-value="2"]) .br-selected:after{
    content: url('images/2-selected.svg') !important;
}

.br-widget:has(a.br-active[data-rating-value="3"]) .br-active:after, .br-widget:has(a.br-selected[data-rating-value="3"]) .br-selected:after{
    content: url('images/3-selected.svg') !important;
}

.br-widget:has(a.br-active[data-rating-value="4"]) .br-active:after, .br-widget:has(a.br-selected[data-rating-value="4"]) .br-selected:after{
    content: url('images/4-selected.svg') !important;
}

.br-widget:has(a.br-active[data-rating-value="5"]) .br-active:after, .br-widget:has(a.br-selected[data-rating-value="5"]) .br-selected:after{
    content: url('images/5-selected.svg') !important;
}

.br-widget:has(a.br-active[data-rating-value="6"]) .br-active:after, .br-widget:has(a.br-selected[data-rating-value="6"]) .br-selected:after{
    content: url('images/6-selected.svg') !important;
}


.br-widget:has(a.br-active[data-rating-value="none"]) .br-active[data-rating-value="1"]:after, .br-widget:has(a.br-selected[data-rating-value="none"]) .br-selected[data-rating-value="1"]:after{
    content: url('images/1.svg') !important;
    opacity: 0.5;

}

.br-widget:has(a.br-active[data-rating-value="none"]) .br-active[data-rating-value="2"]:after, .br-widget:has(a.br-selected[data-rating-value="none"]) .br-selected[data-rating-value="2"]:after{
    content: url('images/2.svg') !important;
    opacity: 0.5;

}

.br-widget:has(a.br-active[data-rating-value="none"]) .br-active[data-rating-value="3"]:after, .br-widget:has(a.br-selected[data-rating-value="none"]) .br-selected[data-rating-value="3"]:after{
    content: url('images/3.svg') !important;
    opacity: 0.5;

}


.br-widget:has(a.br-active[data-rating-value="none"]) .br-active[data-rating-value="4"]:after, .br-widget:has(a.br-selected[data-rating-value="none"]) .br-selected[data-rating-value="4"]:after{
    content: url('images/4.svg') !important;
    opacity: 0.5;

}


.br-widget:has(a.br-active[data-rating-value="none"]) .br-active[data-rating-value="5"]:after, .br-widget:has(a.br-selected[data-rating-value="none"]) .br-selected[data-rating-value="5"]:after{
    content: url('images/5.svg') !important;
    opacity: 0.5;

}

.br-widget:has(a.br-active[data-rating-value="none"]) .br-active[data-rating-value="6"]:after, .br-widget:has(a.br-selected[data-rating-value="none"]) .br-selected[data-rating-value="6"]:after{
    content: url('images/6.svg') !important;
    opacity: 0.5;
}
  
.sd-page .br-theme-css-stars .br-widget .br-active[data-rating-value="none"]:after,.sd-page .br-theme-css-stars .br-widget .br-selected[data-rating-value="none"]:after{
    content: url('images/none-selected_sv.svg') !important;
}

.lang-fi .sd-page .br-theme-css-stars .br-widget .br-active[data-rating-value="none"]:after, .lang-fi .sd-page .br-theme-css-stars .br-widget .br-selected[data-rating-value="none"]:after{
    content: url('images/none-selected_fi.svg') !important;
}

.lang-no .sd-page .br-theme-css-stars .br-widget .br-active[data-rating-value="none"]:after, .lang-no .sd-page .br-theme-css-stars .br-widget .br-selected[data-rating-value="none"]:after{
    content: url('images/none-selected_no.svg') !important;
}



.br-theme-css-stars .br-widget{
    height: 80px;
}

@media (min-width: 600px) {
    .br-theme-css-stars .br-widget{
        height: 40px;
    }
        
}